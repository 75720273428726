import { useMemo } from "react";
import { onError } from "@apollo/client/link/error";
import { withScope } from "@sentry/browser";
import { GraphQLError } from "graphql";

import { ApiError } from "api/api-client";
import { AppVersionState } from "contexts/AppVersion/AppVersionContext";
import { AppErrorCode } from "errors/generated";
import { staticT as t } from "i18n";
import { optionalIntl } from "utils/intl";
import { notifier } from "utils/notifier";

declare module "graphql" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface GraphQLErrorExtensions {
    errorCode?: number;
    errorName?: string;
  }
}

type ErrorExtensions = {
  code?: number;
  expected?: boolean;
  requestId?: string | null;
  operationName?: string;
};

/**
 * Errors coming from a GraphQL operation.
 *
 * We use a class instead of a plain object because it makes it easier to
 * check the type of the exception in a `catch` block using `instanceof`.
 */
export class ParsedGraphQLError extends Error implements ErrorExtensions {
  public display: string;
  public code?: number;
  public expected?: boolean;
  public requestId?: string | null;
  public operationName?: string;
  public extendedApolloError?: ExtendedApolloError;

  constructor(payload: {
    display: string;
    code?: number;
    expected?: boolean;
    requestId?: string | null;
    operationName?: string;
    extendedApolloError?: ExtendedApolloError;
  }) {
    super(payload.display);
    this.display = payload.display;
    this.code = payload.code;
    this.expected = payload.expected;
    this.requestId = payload.requestId;
    this.operationName = payload.operationName;
    this.extendedApolloError = payload.extendedApolloError;
  }
}

type ExtendedGraphQLError = GraphQLError & ErrorExtensions;
type ExtendedApolloError = {
  message: string;
  graphQLErrors: readonly ExtendedGraphQLError[];
  networkError: Error | ApiError | null;
};

export type PartialResultError = {
  operation: string;
  errors: readonly GraphQLError[];
  requestId: string | null;
};

const graphQLExpectedErrorMap: {
  [key: number]: [string, string, string] | string | undefined;
} = {
  [AppErrorCode.INVALID_PHONE]: [
    "Invalid phone number",
    "Numéro de téléphone invalide",
    "Número de telefone inválido",
  ],
  [AppErrorCode.CANNOT_REACH_DRUG_SERVER]:
    "L'outil de recherche de médicament n'est pas disponible pour le moment. Merci de réessayer plus tard ou de contacter l'équipe Nabla si le problème persiste.",
  [AppErrorCode.EXPIRED_VERIFICATION_CODE]: [
    "Expired verification code",
    "Code de vérification expiré",
    "Código de verificação expirado",
  ],
  [AppErrorCode.ALREADY_USED_VERIFICATION_CODE]: [
    "This link has already been used",
    "Ce lien à déjà été utilisé",
    "Este link já foi usado",
  ],
  [AppErrorCode.ENTITY_NOT_FOUND]: [
    "The requested item could not be found.",
    "L'élément demandé est introuvable.",
    "O item solicitado não foi encontrado.",
  ],
  [AppErrorCode.INVALID_VERIFICATION_CODE]: [
    "Invalid code",
    "Code invalide",
    "Código inválido",
  ],
  [AppErrorCode.PHONE_VERIFICATION_LOCKED]: [
    "Too many attempts, please try again later",
    "Trop de tentatives, merci de réessayer un peu plus tard",
    "Demasiadas tentativas, por favor tente novamente mais tarde",
  ],
  [AppErrorCode.ACCOUNT_ALREADY_EXISTS]: [
    "This account already exists.",
    "Ce compte existe déjà.",
    "Esta conta já existe.",
  ],
};

const isExpectedCode = (code: number | undefined) =>
  code !== undefined && graphQLExpectedErrorMap[code] !== undefined;

export const useErrorLink = (
  addPartialResultError: (error: PartialResultError) => void,
) =>
  useMemo(
    () =>
      onError(({ operation, graphQLErrors }) => {
        if (!graphQLErrors?.length) return;
        const requestId = operation.getContext().requestId;
        const firstError = graphQLErrors[0] as ExtendedGraphQLError;
        firstError.requestId = requestId;
        firstError.operationName = operation.operationName;
        const code = graphQLErrors[0].extensions.errorCode;
        firstError.code = code;
        if (!isExpectedCode(code)) {
          addPartialResultError({
            operation: operation.operationName,
            errors: graphQLErrors,
            requestId,
          });
        }
      }),
    [addPartialResultError],
  );

export const parseApolloError = (
  extendedApolloError: ExtendedApolloError,
): ParsedGraphQLError => {
  const { graphQLErrors, networkError } = extendedApolloError;
  if (graphQLErrors.length) {
    const code = graphQLErrors[0].code;
    const expectedLabel = code && graphQLExpectedErrorMap[code];

    let display: string;
    if (expectedLabel) {
      display = optionalIntl(expectedLabel) ?? "";
    } else {
      display = graphQLErrors[0].message;
    }

    return new ParsedGraphQLError({
      display,
      expected: !!expectedLabel,
      code,
      requestId: graphQLErrors[0].requestId,
      operationName: graphQLErrors[0].operationName,
    });
  }
  if (networkError) {
    const display =
      "response" in networkError && networkError.response
        ? `${t("graphql_client.errors.http_error")} ${
            networkError.response.status
          }`
        : t("graphql_client.errors.connection_error");

    const code =
      networkError instanceof ApiError
        ? networkError.response?.status
        : undefined;

    return new ParsedGraphQLError({ code, display, extendedApolloError });
  }
  return new ParsedGraphQLError({
    display: t("graphql_client.errors.unknown_error"),
  });
};

export const notifyGraphQLError = (
  appVersionState: AppVersionState,
  authState: "LOGGED_OUT" | "LOGGED_IN" | undefined,
  error: ExtendedApolloError,
  mode?: "SENTRY_ONLY",
) => {
  console.error(error);
  const parsedError = parseApolloError(error);
  const isOutdated = appVersionState === "OUTDATED";
  // Don't show the error if the app is outdated:
  //  - if there is no user-blocking error beyond that, the banner will prompt
  //  the user to update the app.
  //  - if there is a user-blocking error, the error will be shown to the user
  //  and have a big "Reload" button which will trigger the update if needed.
  if (
    mode !== "SENTRY_ONLY" &&
    !isOutdated &&
    !(parsedError.code === 401 && authState === "LOGGED_IN")
  ) {
    notifier.error({ user: parsedError.display });
  }

  if (isExpectedCode(parsedError.code)) return; // Don't log expected errors
  if (error.networkError) return; // Already logged in apiCall

  withScope((scope) => {
    if (parsedError.requestId) {
      scope.setTag("request-id", parsedError.requestId);
    }
    if (parsedError.operationName) {
      scope.setTag("operation", parsedError.operationName);
    }
    notifier.error({ sentry: { exception: error } });
  });
};
