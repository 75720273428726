import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { AccountJwt, jwtRegion, SessionLoginMethod } from "api/jwt";
import { useLoggedInAuth } from "auth/AuthContext";
import {
  CopilotWebappAuthData,
  SIGN_IN_CALLBACK_CHROME_PATH,
  SIGN_IN_CALLBACK_PATH,
} from "auth/shared";
import { VerifiedLoginMethodKnownValue } from "auth/utils";
import {
  CopilotApiUserSummaryFragment,
  CopilotAssistantUserSummaryFragment,
  IdentityFragment,
  LoginMethodKnownValue,
  ProviderSummaryFragment,
} from "generated/account";
import {
  TargetFrontend,
  TargetFrontendKnownValues,
} from "generated/unauth-account";
import { useStableCallback } from "hooks/useStableCallback";
import { routes } from "routes";
import { isKnownValue } from "utils/enum";
import {
  copilotMobileAppDeeplinkUrl,
  copilotWebappUrl,
} from "utils/environment";

// ----- Identities and statuses.

export type PickableIdentityStatus =
  | "COMPATIBLE"
  | "REQUIRES_PASSWORD_LOGIN"
  | "REQUIRES_GOOGLE_LOGIN"
  | "REQUIRES_MFA";

export type UnpickableIdentityStatus =
  | "DEACTIVATED" // The provider was manually deactivated.
  | "UNSUPPORTED_PASSWORD_LOGIN"
  | "UNSUPPORTED_GOOGLE_LOGIN"
  | "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN";

export type IdentityStatus = PickableIdentityStatus | UnpickableIdentityStatus;

type IdentityWithStatus<Status extends IdentityStatus> = {
  uuid: UUID;
  status: Status;
} & (
  | ({ type: "DOCTOR" } & ProviderSummaryFragment)
  | ({ type: "COPILOT_API_USER" } & CopilotApiUserSummaryFragment)
  | ({ type: "COPILOT_ASSISTANT_USER" } & CopilotAssistantUserSummaryFragment)
);

export type Identity = IdentityWithStatus<IdentityStatus>;
export type PickableIdentity = IdentityWithStatus<PickableIdentityStatus>;

export const isPickableStatus = (
  status: IdentityStatus,
): status is PickableIdentityStatus => {
  switch (status) {
    case "COMPATIBLE":
    case "REQUIRES_PASSWORD_LOGIN":
    case "REQUIRES_GOOGLE_LOGIN":
    case "REQUIRES_MFA":
      return true;
    case "DEACTIVATED":
    case "UNSUPPORTED_PASSWORD_LOGIN":
    case "UNSUPPORTED_GOOGLE_LOGIN":
    case "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN":
      return false;
  }
};

export const isPickableIdentity = (
  identity: Identity,
): identity is PickableIdentity => isPickableStatus(identity.status);

// ----- Navigation.

export type IdentityPickerState = {
  shouldAutomaticallyPickSingleIdentity?: boolean;
} | null;

export type IdentityPickerConfirmationState = {
  canGoBack?: boolean;
} | null;

// ----- Hooks.

// If the picked identity is compatible with the current login method, it will
// be selected automatically. Otherwise, we redirect the user to the identity
// confirmation view, where they will be asked either to re-login with the
// proper login method or to set up MFA when applicable.
//
// The current route is likely to become unreachable when changing identities,
// e.g. because it targets the UUID of an entity that the new identity doesn't
// have access to, so we redirect to the index by default.
export const usePickIdentity = () => {
  const auth = useLoggedInAuth();
  const navigate = useNavigate();
  const { redirectToTargetFrontend } =
    useRedirectToCopilotAssistantTargetFrontend();

  return useCallback(
    (
      identity: PickableIdentity | null,
      {
        canGoBack = false,
        replace = false,
        redirectToIndex = true,
        targetFrontend = null,
        hardNavigate = false,
      }: {
        canGoBack?: boolean;
        replace?: boolean;
        redirectToIndex?: boolean;
        targetFrontend?: TargetFrontend | null;
        hardNavigate?: boolean;
      } = {},
    ) => {
      if (!auth.canChangeIdentity) return;
      if (!identity) {
        auth.setCurrentIdentity(null);
        navigate(routes.PICK_IDENTITY);
      } else if (identity.status === "COMPATIBLE") {
        if (identity.type === "COPILOT_ASSISTANT_USER") {
          redirectToTargetFrontend(
            targetFrontend ?? "COPILOT_WEB_APP",
            identity.uuid,
            auth.getLatestAccountTokens(),
          );
        } else {
          auth.setCurrentIdentity({
            type: identity.type,
            uuid: identity.uuid,
          });
          if (redirectToIndex) navigate("/");
        }
      } else {
        // For some obscure reason, navigate sometimes doesn't trigger here
        // In those cases we directly change window location (and ignore other navigate params)
        if (hardNavigate) {
          window.location.href = `${routes.PICK_IDENTITY}/${identity.uuid}`;
        } else {
          const state: IdentityPickerConfirmationState = { canGoBack };
          navigate(`${routes.PICK_IDENTITY}/${identity.uuid}`, {
            state,
            replace,
          });
        }
      }
    },
    [auth, navigate, redirectToTargetFrontend],
  );
};

const useRedirectToCopilotAssistantTargetFrontend = () => {
  const redirectToCopilotAssistantTargetFrontend = useStableCallback(
    (
      targetFrontend: TargetFrontend,
      identity: UUID,
      latestTokens: { accessToken: AccountJwt; refreshToken: AccountJwt },
    ) => {
      if (!isKnownValue(targetFrontend, TargetFrontendKnownValues)) {
        throw new Error(
          `Can't redirect to unknown frontend: ${targetFrontend}`,
        );
      }

      const path: string = {
        COPILOT_WEB_APP: SIGN_IN_CALLBACK_PATH,
        COPILOT_CHROME_EXTENSION: SIGN_IN_CALLBACK_CHROME_PATH,
        COPILOT_MOBILE_APP: SIGN_IN_CALLBACK_PATH,
      }[targetFrontend];

      const copilotAuthData: CopilotWebappAuthData = {
        copilotAssistantUserUuid: identity,
        accessToken: latestTokens.accessToken.token,
        refreshToken: latestTokens.refreshToken.token,
        nablaRegion: jwtRegion(latestTokens.refreshToken),
      };
      const encodedCopilotAuthData = btoa(JSON.stringify(copilotAuthData));
      const url: URL = {
        COPILOT_WEB_APP: new URL(copilotWebappUrl),
        COPILOT_CHROME_EXTENSION: new URL(copilotWebappUrl),
        COPILOT_MOBILE_APP: new URL(copilotMobileAppDeeplinkUrl),
      }[targetFrontend];
      url.pathname += path;
      url.hash = `#${encodedCopilotAuthData}`;
      window.location.href = url.toString();
    },
  );

  return { redirectToTargetFrontend: redirectToCopilotAssistantTargetFrontend };
};

// ----- GraphQL conversion.

export type KnownIdentityFragment = Extract<
  IdentityFragment,
  {
    __typename:
      | "ProviderIdentity"
      | "CopilotApiUserIdentity"
      | "CopilotAssistantUserIdentity";
  }
>;

export const getSubOrganizationFromGql = (identity: KnownIdentityFragment) => {
  switch (identity.__typename) {
    case "ProviderIdentity":
      return identity.provider.subOrganization;
    case "CopilotApiUserIdentity":
      return identity.user.subOrganization;
    case "CopilotAssistantUserIdentity":
      return identity.user.subOrganization;
  }
};

export const getOrganizationFromGql = (identity: KnownIdentityFragment) =>
  getSubOrganizationFromGql(identity).organization;

export const getIdentityFromGql = (
  {
    identity,
  }: {
    identity: KnownIdentityFragment;
  },
  currentLoginMethod: VerifiedLoginMethodKnownValue,
): Identity => {
  const status = getStatusFromGql(identity, currentLoginMethod);

  switch (identity.__typename) {
    case "ProviderIdentity":
      return { type: "DOCTOR", status, ...identity.provider };
    case "CopilotApiUserIdentity":
      return { type: "COPILOT_API_USER", status, ...identity.user };
    case "CopilotAssistantUserIdentity":
      return { type: "COPILOT_ASSISTANT_USER", status, ...identity.user };
  }
};

const getStatusFromGql = (
  identity: IdentityFragment,
  currentMethod: VerifiedLoginMethodKnownValue,
): IdentityStatus => {
  if (
    identity.__typename === "ProviderIdentity" &&
    identity.provider.deactivated
  ) {
    return "DEACTIVATED";
  }

  const acceptableMethods = identity.acceptableLoginMethods;
  if (acceptableMethods.includes(currentMethod)) return "COMPATIBLE";
  switch (currentMethod) {
    case "GOOGLE":
      return acceptableMethods.includes("PASSWORD_WITH_MFA") ||
        acceptableMethods.includes("PASSWORD_WITHOUT_MFA")
        ? "REQUIRES_PASSWORD_LOGIN"
        : "UNSUPPORTED_GOOGLE_LOGIN";
    case "PASSWORD_WITHOUT_MFA":
      return acceptableMethods.includes("PASSWORD_WITH_MFA")
        ? "REQUIRES_MFA"
        : acceptableMethods.includes("GOOGLE")
        ? "REQUIRES_GOOGLE_LOGIN"
        : "UNSUPPORTED_PASSWORD_LOGIN";
    case "PASSWORD_WITH_MFA":
      return acceptableMethods.includes("GOOGLE")
        ? "REQUIRES_GOOGLE_LOGIN"
        : "UNSUPPORTED_PASSWORD_LOGIN";
    case "ORGANIZATION_INITIATED":
    case "ONE_TIME_TOKEN_WITHOUT_MFA":
      return acceptableMethods.includes("ONE_TIME_TOKEN_WITH_MFA")
        ? "REQUIRES_MFA"
        : acceptableMethods.includes("GOOGLE")
        ? "REQUIRES_GOOGLE_LOGIN"
        : "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN";
    case "ONE_TIME_TOKEN_WITH_MFA":
      return acceptableMethods.includes("GOOGLE")
        ? "REQUIRES_GOOGLE_LOGIN"
        : "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN";
    case "EPIC_SSO":
    case "CERNER_SSO":
    case "ATHENA_SSO":
    case "SAML_SSO":
      return "UNSUPPORTED_ONE_TIME_TOKEN_LOGIN";
  }
};

export const getGqlLoginMethod = (
  sessionLoginMethod: SessionLoginMethod,
): LoginMethodKnownValue =>
  ({
    password_without_mfa: "PASSWORD_WITHOUT_MFA" as const,
    password_with_mfa: "PASSWORD_WITH_MFA" as const,
    one_time_token_without_mfa: "ONE_TIME_TOKEN_WITHOUT_MFA" as const,
    one_time_token_with_mfa: "ONE_TIME_TOKEN_WITH_MFA" as const,
    google: "GOOGLE" as const,
    organization_initiated: "ORGANIZATION_INITIATED" as const,
  }[sessionLoginMethod]);
