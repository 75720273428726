export const AppErrorCode = {
  // Generic HTTP-like error codes
  BAD_REQUEST: 400,
  HTTP_UNAUTHORIZED: 401,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,
  HTTP_UNSUPPORTED_MEDIA_TYPE: 415,
  HTTP_UNPROCESSABLE_ENTITY: 422,
  HTTP_TOO_MANY_REQUESTS: 429,
  HTTP_TIMEOUT: 408,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_ROUTING_ERROR: 9999,

  // Generic authorization errors (10xxx)
  ACCESS_TO_ENTITY_UNAUTHORIZED: 10000,
  PERMISSION_REQUIRED: 10001,
  ILLEGAL_ACCESS_TO_MEDICAL_DATA: 10012,
  ILLEGAL_ACCESS_TO_ADMINISTRATIVE_DATA: 10013,
  IDENTIFIER_NOT_WHITELISTED: 10017,
  AUTO_ACCESS_CHECK_FAILURE: 10018,

  // Generic errors for unconditionally bad user inputs (30xxx)
  INVALID_EMAIL: 30002,
  INVALID_PHONE: 30003,
  INSECURE_PASSWORD: 30004,
  INVALID_NAME: 30005,
  INVALID_URL: 30025,
  BAD_PAGINATION_CURSOR: 30020,
  USERNAME_OR_FULLNAME_REQUIRED: 30103,
  INVALID_UUID_FORMAT: 30104,
  INVALID_GATEKEEPER_UPDATE_INPUT: 50008,
  INVALID_BASE_64: 30105,

  // Generic 'bad state' errors (40xxx)
  ENTITY_NOT_FOUND: 20000,
  ENTITY_IS_NOT_UNIQUE: 40052,
  INVALID_REGION: 40100,
  PING_TIMEOUT: 40101,

  // Appointments and availabilities (43xxx)
  UNAUTHORIZED_APPOINTMENT_AVAILABILITY_EDITION: 10015,
  EVENT_MUST_BE_NON_RECURRENT: 30017,
  INVALID_RRULE_FOR_CALENDAR_EVENT: 30018,
  EVENT_MUST_BE_RECURRENT: 30024,
  INVALID_APPOINTMENT_CREATION_INPUT: 40032,
  PATIENT_CAN_NOT_CANCEL_PAST_APPOINTMENT: 40040,
  ATTEMPTED_TO_CANCEL_FINALIZED_APPOINTMENT: 42006,
  PROVIDER_HAS_NO_ADDRESS_FOR_PHYSICAL_APPOINTMENT: 42013,
  UNAUTHORIZED_ADDRESS_EDITION: 43001,
  CALENDAR_EVENTS_MUST_BE_ALIGNED_ON_THE_SECOND: 43007,
  APPOINTMENT_ALREADY_HAS_A_NOTE: 43009,
  APPOINTMENT_SLOT_UNAVAILABLE: 43010,
  APPOINTMENT_ALREADY_CANCELLED: 43011,
  APPOINTMENT_TOO_SOON_FOR_CANCELLATION: 43013,
  APPOINTMENT_NOTE_ALREADY_LOCKED: 43014,
  APPOINTMENT_NOTE_LOCKED: 43015,
  CANNOT_AMEND_WHILE_UNLOCKED_NOTE: 43016,
  AVAILABILITY_SUPPORTS_NO_APPOINTMENT_MODES: 43017,
  APPOINTMENT_SCHEDULING_REQUIRES_PAYMENT: 43018,
  APPOINTMENT_PRICE_EVALUATION_FAILED: 43019,
  APPOINTMENT_HAS_NO_RECORDING: 43020,
  APPOINTMENT_RECORDING_IN_PROGRESS: 43021,
  APPOINTMENT_RECORDING_ERROR: 43022,

  // EHR (45xxx)
  TAG_BLANK: 30009,
  ACCESSOR_CANNOT_DELETE_EHR_ITEM: 45004,
  ONLY_DATA_POINTS_FROM_WEBAPP_CAN_BE_EDITED: 45007,
  ACCESSOR_CANNOT_UPDATED_EXTRACTION_STATUS: 45008,
  OBSERVATION_INPUT_INCOHERENT: 45009,

  // Experiences (46xxx)
  EXPERIENCE_ALREADY_CLOSED: 40013,
  EXPERIENCE_NOT_CLOSED: 40014,
  USER_NOT_IN_EXPERIENCE: 40031,
  NO_REACTION_TO_MESSAGE_IN_EXPERIENCE_WITH_PATIENT: 46007,
  CANNOT_EDIT_DELETED_MESSAGE: 46008,
  NOT_A_DOCTOR_EXPERIENCE: 46009,
  ONLY_ONE_SCHEDULED_MESSAGE_BY_EXPERIENCE: 46013,
  INVALID_EXPERIENCE_PATIENT: 46014,
  USER_NOT_AUTHORIZED_TO_DELETE_MESSAGE: 46015,
  UNAUTHORIZED_TO_ASSIGN_THIS_DOCTOR: 46020,
  NOT_A_SINGLE_PATIENT_EXPERIENCE: 46024,
  CANNOT_UPDATE_PATIENT_FROM_SINGLE_PATIENT_EXPERIENCE: 46025,
  MULTI_PATIENTS_CONVERSATION_MUST_HAVE_AT_LEAST_ONE_PATIENT: 46026,
  SINGLE_PATIENT_CONVERSATION_MUST_HAVE_ONE_PATIENT: 46027,
  PROVIDERS_ONLY_CONVERSATION_CANNOT_HAVE_PATIENTS: 46028,
  EXPERIENCE_ALREADY_LOCKED: 46029,
  EXPERIENCE_ALREADY_UNLOCKED: 46030,
  CANNOT_SEND_MESSAGE_IN_LOCKED_CONVERSATION: 46031,
  CANNOT_CREATE_SEVERAL_EXPERIENCES_IN_SINGLE_CONVERSATION_MODE: 46032,
  NOT_A_MULTI_PATIENTS_EXPERIENCE: 46033,
  PATIENT_DOES_NOT_EXIST_IN_CONVERSATION: 46034,
  PROVIDERS_ONLY_CONVERSATIONS_NOT_SUPPORTED: 46035,
  INCOHERENT_MESSAGE_FILE_TYPE: 46036,
  NOT_ALL_MESSAGES_ARE_DELETED: 46037,

  // Device & notifications (48xxx)
  UNAUTHORIZED_REMOVE_DEVICE_ATTEMPT: 10014,
  DEVICE_BELONGS_TO_ANOTHER_USER: 48000,

  // Doctor (50xxx)
  ADMIN_CANNOT_SEE_MEDICAL_DATA: 10010,
  INVALID_RPPS: 50003,
  INVALID_ADELI_NUMBER: 50004,
  INVALID_AM_NUMBER: 50005,
  OPERATION_REQUIRES_A_DEACTIVATED_DOCTOR: 50006,
  OPERATION_REQUIRES_AN_ACTIVE_DOCTOR: 50007,
  INVALID_GMC_NUMBER: 50010,

  // Patient (51xxx)
  INVALID_USERNAME: 51004,
  USERNAME_ALREADY_IN_USE: 51005,

  // FileUpload (55xxx)
  AVATAR_TOO_BIG: 30006,
  CANNOT_DELETE_FILE_USED_IN_SEVERAL_CONTEXTS: 55001,

  // External assets (56xxx)
  CANNOT_REACH_DRUG_SERVER: 56001,
  PUBLICLY_LISTED_DRUG_NOT_FOUND_FOR_EXTERNAL_ID: 56002,

  // Verification codes (58xxx)
  PHONE_VERIFICATION_LOCKED: 51008,
  EMAIL_VERIFICATION_LOCKED: 51009,
  INVALID_VERIFICATION_CODE: 40006,
  EXPIRED_VERIFICATION_CODE: 58001,
  ALREADY_USED_VERIFICATION_CODE: 58002,
  VERIFICATION_CODE_RATE_LIMIT_EXCEEDED: 58003,
  VERIFICATION_CODE_INVALIDATED_BY_MORE_RECENT_CODES: 58004,
  INAPPROPRIATE_VERIFICATION_CODE_INTENT: 58005,

  // Mentions (59xxx)
  WRONG_INPUT_FOR_MENTION: 59001,
  TEXT_INCOMPATIBLE_WITH_MENTIONS: 59003,

  // ML models (64xxx)
  ML_MODEL_NOT_READY: 64000,
  TOO_MANY_ONGOING_TRAININGS: 64001,

  // Organizations (65xxx)
  ORGANIZATION_ID_DOES_NOT_EXIST: 10021,
  PATIENT_FIELD_INVALID_KEY: 50017,
  SUB_ORGANIZATION_CREATION_IS_DISABLED: 65001,
  SUB_ORGANIZATION_IS_NOT_EMPTY: 65002,
  ORGANIZATION_STRING_ID_INVALID: 65003,
  ORGANIZATION_FAX_DISABLED: 65004,
  ORGANIZATION_FAX_THRESHOLD_REACHED: 65005,
  WHITE_LABEL_MODE_DISABLED: 65006,
  CANNOT_DELETE_NON_TEST_ORGANIZATION: 65007,

  // Webhooks (66xxx)
  WEBHOOK_ALREADY_BEING_ATTEMPTED: 66000,
  FAKE_PAYLOAD_IS_NOT_JSON: 66001,

  // Web call invitations (67xxx)
  WEB_CALL_INVITATION_SLOT_ALREADY_PICKED: 67000,
  WEB_CALL_INVITATION_END_AT_BEFORE_START_AT: 67007,
  WEB_CALL_INVITATION_NO_PROVIDERS: 67008,
  WEB_CALL_INVITATION_CALL_DURATION_NOT_POSITIVE: 67009,
  WEB_CALL_INVITATION_INVALID_DATE_RANGE: 67011,
  WEB_CALL_INVITATION_NO_CALL_DURATION: 67012,
  WEB_CALL_INVITATION_EXTERNAL_URL_MISSING: 67013,
  WEB_CALL_INVITATION_EXTERNAL_URL_INVALID: 67014,

  // Apero (68xxx)
  APERO_CLIENT_ERROR: 68000,
  PATIENT_MUST_HAVE_FIRST_AND_LAST_NAME: 68001,
  CANNOT_HAVE_MORE_THAN_10_COVERAGES: 68002,
  APERO_SERVER_ERROR: 68003,
  APERO_UNKNOWN_PROVIDER: 68004,
  APERO_UNKNOWN_PATIENT: 68005,
  APERO_DOCTOR_IS_NOT_REGISTERED: 68006,
  APERO_PATIENT_IS_NOT_REGISTERED: 68007,
  APERO_UNKNOWN_INVOICE: 68008,
  APERO_CANNOT_PERFORM_ELIGIBILITY_CHECK_MISSING_DOCTOR: 68009,
  APERO_CANNOT_PERFORM_ELIGIBILITY_CHECK_MISSING_PATIENT: 68010,
  APERO_NOT_SUPPORTED: 68011,

  // Validation (69xxx)
  VALIDATION_ERROR: 69000,
  VALIDATION_ERROR_ALREADY_EXISTS: 69001,
  VALIDATION_ERROR_BLANK: 69002,
  VALIDATION_ERROR_TOO_SHORT: 69003,
  VALIDATION_ERROR_TOO_LONG: 69004,
  VALIDATION_ERROR_INVALID_FORMAT: 69005,
  VALIDATION_ERROR_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO: 69006,
  VALIDATION_ERROR_VALUE_MUST_BE_GREATER_THAN: 69011,
  VALIDATION_ERROR_EQUAL_TO: 69007,
  VALIDATION_ERROR_VALUE_MUST_BE_LESS_THAN: 69008,
  VALIDATION_ERROR_VALUE_MUST_BE_LESS_THAN_OR_EQUAL_TO: 69009,
  VALIDATION_ERROR_OTHER_THAN: 69010,

  // Notes and Notes template (70xxx)
  INVALID_ICD10_CODE: 70000,
  ONLY_AUTHOR_CAN_EDIT_NOTE_ANNOTATION: 76000,

  // Bills (71xxx)
  INVALID_CPT_CODE: 71000,
  CPT_CODE_DOES_NOT_HAVE_FEE_ASSOCIATED: 71001,

  // Google Calendar sync (72xxx)
  GOOGLE_CALENDAR_SYNC_IS_DISABLED: 72000,

  // Tasks (73xxx)
  PATIENT_ASSIGNED_TASK_INVALID: 73000,

  // Bravado (74xxx)
  NO_BRAVADO_CREDENTIALS: 74000,
  PATIENT_FIND_OR_CREATE_ERROR: 74001,
  NOT_ENOUGH_PATIENT_INFO: 74002,
  BLANK_BRAVADO_CREDENTIALS: 74003,

  // MFA & Authentication (75xxx)
  INVALID_MFA_CODE: 40044,
  FAILED_TO_SETUP_MFA_METHOD: 75000,
  MFA_ALREADY_SETUP: 75001,
  MFA_METHOD_NOT_SUPPORTED: 75002,
  INVALID_MFA_BY_SMS_ANTI_ABUSE_TOKEN: 75003,
  MFA_IS_REQUIRED: 75004,
  SSO_IS_REQUIRED: 75005,

  // Faxes (77xxx)
  INVALID_PHONE_NUMBER_FOR_FAX: 77001,

  // REST (78xxx)
  TOO_MANY_ITEMS_IN_QUERY_PARAMS_LIST: 78000,

  // Photon (79xxx)
  NO_PHOTON_CREDENTIALS: 79000,
  PHOTON_PATIENT_FIND_OR_CREATE_ERROR: 79001,
  BLANK_PHOTON_CREDENTIALS: 79002,
  PHOTON_UNKNOWN_ORDER_ID: 79004,
  PHOTON_NOT_ENOUGH_PATIENT_INFO: 79005,

  // Accounts (80xxx)
  ACCOUNT_ATTACHMENT_INVALID: 80000,
  ACCOUNT_ALREADY_EXISTS_IN_ANOTHER_REGION: 80001,
  ACCOUNT_ALREADY_EXISTS: 80002,
  ACCOUNT_EMAIL_NOT_VERIFIED: 80003,
  ACCOUNT_EMAIL_ALREADY_VERIFIED: 80004,
  EMAIL_DOMAIN_OWNED_BY_AN_ORGANIZATION_IN_ANOTHER_REGION: 80005,
  SSO_AUTO_PROVISIONING_DISABLED: 80006,
  EMAIL_DOMAIN_OWNED_BY_ANOTHER_ORGANIZATION: 80007,
  ACCOUNT_ALREADY_HAS_AN_IDENTITY_IN_ANOTHER_SUBORG: 80008,

  // Login (50xxx)
  PASSWORD_LOGIN_DISABLED: 50015,
  GOOGLE_LOGIN_DISABLED: 50016,
  INVALID_LOGIN_CREDENTIALS: 50018,
  TOO_MANY_WRONG_PASSWORDS: 50019,
  TOO_MANY_WRONG_MFA_CODES: 50020,
  MISSING_CREDENTIALS: 50021,
  ONE_TIME_LOGIN_JWT_ALREADY_USED: 50022,
  ONE_TIME_LOGIN_JWT_EXPIRED: 50023,
  SESSION_REACHED_MAX_DURATION: 50024,
  JWT_EXPIRED: 50025,

  // Note normalization tool (82xxx)
  NOTE_SECTION_HAS_ALREADY_STARTED: 82000,
  REQUEST_DOCTOR_IS_NOT_LABELER: 82001,

  // Copilot API (83xxx)
  COPILOT_INVALID_NOTE: 83000,
  COPILOT_AUDIO_FILE_TOO_BIG: 83001,
  COPILOT_NORMALIZATION_FAILURE: 83002,
  COPILOT_MISSING_PLAN_FOR_PATIENT_INSTRUCTIONS: 83004,
  NOTE_GENERATION_TRANSCRIPT_TOO_SHORT: 83005,
  NOTE_GENERATION_NO_MEDICAL_DATA_FOUND: 83006,
  COPILOT_STREAMING_TOO_LONG: 83007,
  COPILOT_AUDIO_CHUNK_TOO_LONG: 83008,
  COPILOT_UNKNOWN_STREAM_ID: 83009,
  COPILOT_INCONSISTENT_CONFIGURATION: 83010,
  COPILOT_AUDIO_STREAM_IDLE_TIMEOUT: 83011,
  COPILOT_API_USER_ALREADY_DEACTIVATED: 83012,
  COPILOT_API_USER_ALREADY_ACTIVATED: 83013,
  INVALID_NOTE_GENERATION_PARAMETERS: 83014,
  UNEXPECTED_AUDIO_CHUNK_SEQ_ID: 83015,
  AUDIO_CHUNKS_BUFFER_OVERFLOW: 83016,
  TRANSCRIPT_TOO_LONG_FOR_NOTE_GENERATION: 83017,
  NOTE_GENERATION_SETTINGS_NOT_SUPPORTED: 83018,
  TEMPLATE_CUSTOMIZATION_OPTION_NOT_AVAILABLE: 83019,

  // Copilot User API (84xxx)
  RATING_NOT_BETWEEN_1_AND_5: 84000,

  // Rate limitation (85xxx)
  ORGANIZATION_RATE_LIMITED: 85000,
  USER_RATE_LIMITED: 85001,

  // Encounters (86xxx)
  STORED_ENCOUNTER_DATA_IS_MORE_RECENT: 86000,
  ENCOUNTER_UUID_ALREADY_USED_BY_ANOTHER_USER: 86001,

  // FHIR (87xxx)
  UNAUTHORIZED_FHIR_CALL: 87000,
  CANNOT_EXPORT_NOTE_ENCOUNTER_NOT_FOUND: 87001,
  FHIR_API_ERROR: 87002,
  SMART_LAUNCH_ERROR: 87003,

  // Async Requests (88xxx)
  ASYNC_REQUEST_EXECUTION_TIMED_OUT: 88000,
};
