// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type EmptyObject = "EMPTY" | "FUTURE_VALUE";
export type EmptyObjectKnownValue = "EMPTY";
export const EmptyObjectKnownValues: EmptyObjectKnownValue[] = [ "EMPTY" ];
export type CopilotApiUserRole = "ADMINISTRATOR" | "PRACTITIONER" | "FUTURE_VALUE";
export type CopilotApiUserRoleKnownValue = "ADMINISTRATOR" | "PRACTITIONER";
export const CopilotApiUserRoleKnownValues: CopilotApiUserRoleKnownValue[] = [ "ADMINISTRATOR","PRACTITIONER" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "PORTUGUESE" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH" | "PORTUGUESE";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH","PORTUGUESE" ];
export type NablaProduct = "CARE_PLATFORM" | "COPILOT_CLINIC" | "COPILOT_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "CARE_PLATFORM" | "COPILOT_CLINIC" | "COPILOT_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "CARE_PLATFORM","COPILOT_CLINIC","COPILOT_API" ];
export type Gender = "MALE" | "FEMALE" | "FUTURE_VALUE";
export type GenderKnownValue = "MALE" | "FEMALE";
export const GenderKnownValues: GenderKnownValue[] = [ "MALE","FEMALE" ];
export type IdentityKind = "SUPERUSER" | "PROVIDER" | "COPILOT_ASSISTANT_USER" | "COPILOT_API_USER" | "FUTURE_VALUE";
export type IdentityKindKnownValue = "SUPERUSER" | "PROVIDER" | "COPILOT_ASSISTANT_USER" | "COPILOT_API_USER";
export const IdentityKindKnownValues: IdentityKindKnownValue[] = [ "SUPERUSER","PROVIDER","COPILOT_ASSISTANT_USER","COPILOT_API_USER" ];
export type LoginMethod = "PASSWORD_WITHOUT_MFA" | "PASSWORD_WITH_MFA" | "ONE_TIME_TOKEN_WITHOUT_MFA" | "ONE_TIME_TOKEN_WITH_MFA" | "GOOGLE" | "EPIC_SSO" | "CERNER_SSO" | "ATHENA_SSO" | "SAML_SSO" | "INITIAL_CREATION" | "ORGANIZATION_INITIATED" | "FUTURE_VALUE";
export type LoginMethodKnownValue = "PASSWORD_WITHOUT_MFA" | "PASSWORD_WITH_MFA" | "ONE_TIME_TOKEN_WITHOUT_MFA" | "ONE_TIME_TOKEN_WITH_MFA" | "GOOGLE" | "EPIC_SSO" | "CERNER_SSO" | "ATHENA_SSO" | "SAML_SSO" | "INITIAL_CREATION" | "ORGANIZATION_INITIATED";
export const LoginMethodKnownValues: LoginMethodKnownValue[] = [ "PASSWORD_WITHOUT_MFA","PASSWORD_WITH_MFA","ONE_TIME_TOKEN_WITHOUT_MFA","ONE_TIME_TOKEN_WITH_MFA","GOOGLE","EPIC_SSO","CERNER_SSO","ATHENA_SSO","SAML_SSO","INITIAL_CREATION","ORGANIZATION_INITIATED" ];
export type DoctorRole = "NABLA_ADMINISTRATOR" | "NABLA_MEDICAL_STAFF" | "REVIEWER" | "LABELLER" | "FUTURE_VALUE";
export type DoctorRoleKnownValue = "NABLA_ADMINISTRATOR" | "NABLA_MEDICAL_STAFF" | "REVIEWER" | "LABELLER";
export const DoctorRoleKnownValues: DoctorRoleKnownValue[] = [ "NABLA_ADMINISTRATOR","NABLA_MEDICAL_STAFF","REVIEWER","LABELLER" ];
export type MfaMethodKind = "TOTP" | "SMS" | "FUTURE_VALUE";
export type MfaMethodKindKnownValue = "TOTP" | "SMS";
export const MfaMethodKindKnownValues: MfaMethodKindKnownValue[] = [ "TOTP","SMS" ];
export type NablaRegion = "EU" | "US" | "FUTURE_VALUE";
export type NablaRegionKnownValue = "EU" | "US";
export const NablaRegionKnownValues: NablaRegionKnownValue[] = [ "EU","US" ];
export type CloudRegion = "EU_WEST1" | "US_CENTRAL1" | "FUTURE_VALUE";
export type CloudRegionKnownValue = "EU_WEST1" | "US_CENTRAL1";
export const CloudRegionKnownValues: CloudRegionKnownValue[] = [ "EU_WEST1","US_CENTRAL1" ];



export type SetupMfaInput = { code: string; methodKind: MfaMethodKind }
export type CopilotApiOrganizationInput = { id: string; displayName: string; locale: SupportedLocale; timezone: TimeZone; attributionData?: Maybe<string> }
export type InitialCopilotApiUserIdentityInput = { roles?: Array<CopilotApiUserRole>; firstName?: Maybe<string>; lastName?: Maybe<string> }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "Identity": [
    "SuperuserIdentity",
    "ProviderIdentity",
    "CopilotAssistantUserIdentity",
    "CopilotApiUserIdentity"
  ],
  "MfaState": [
    "NotSetupMfaState",
    "SetupMfaState"
  ],
  "MfaMethod": [
    "TotpMfaMethod",
    "SmsMfaMethod"
  ]
};
export type PossibleTypes = {
  Identity: ("SuperuserIdentity" | "ProviderIdentity" | "CopilotAssistantUserIdentity" | "CopilotApiUserIdentity")
  MfaState: ("NotSetupMfaState" | "SetupMfaState")
  MfaMethod: ("TotpMfaMethod" | "SmsMfaMethod")
};

export type EntityName = 
  | "Account"
  | "SuperuserIdentity"
  | "ProviderIdentity"
  | "CopilotAssistantUserIdentity"
  | "CopilotApiUserIdentity"
  | "Superuser"
  | "Doctor"
  | "CopilotAssistantUser"
  | "CopilotApiUser"
  | "Organization"
  | "SubOrganization"
  | "FileUpload"
export type EntityFieldMap = {
  Account: "uuid" | "email" | "unverifiedEmail" | "locale" | "timezone" | "identities" | "hasPassword" | "mfaState";
  SuperuserIdentity: "uuid" | "kind" | "superuser" | "acceptableLoginMethods";
  ProviderIdentity: "uuid" | "kind" | "provider" | "acceptableLoginMethods";
  CopilotAssistantUserIdentity: "uuid" | "kind" | "user" | "acceptableLoginMethods";
  CopilotApiUserIdentity: "uuid" | "kind" | "user" | "acceptableLoginMethods";
  Superuser: "uuid" | "region" | "cloudRegion";
  Doctor: "uuid" | "deactivated" | "roles" | "organization" | "subOrganization" | "canAccessOrganizationUserApi";
  CopilotAssistantUser: "uuid" | "organization" | "subOrganization" | "canAccessOrganizationUserApi";
  CopilotApiUser: "uuid" | "organization" | "subOrganization" | "canAccessOrganizationUserApi";
  Organization: "uuid" | "stringId" | "displayName" | "product";
  SubOrganization: "uuid" | "organization" | "displayName" | "avatar";
  FileUpload: "uuid" | "urlV2"
}
export type QueryVariablesMap = {  }

export type QueryName = 
  | "AccountIdentities"
  | "GetFreshMfaBySmsAntiAbuseToken"

const AccountSubOrganizationSummaryFragmentDocument = gql`
fragment AccountSubOrganizationSummary on SubOrganization {
  uuid
  displayName
  organization {
    uuid
    stringId
    displayName
    product
  }
  avatar {
    urlV2 {
      url
      expiresAt
    }
  }
}

`
export const AccountSubOrganizationSummaryFragmentProps: Fragment<AccountSubOrganizationSummaryFragment> = {
  document: AccountSubOrganizationSummaryFragmentDocument,
  fragmentName: "AccountSubOrganizationSummary",
  entityName: "SubOrganization",
  __do_not_use_Data: null
}
export type AccountSubOrganizationSummaryFragment = { __typename: "SubOrganization"; uuid: UUID; displayName: Maybe<string>; organization: { __typename: "Organization"; uuid: UUID; stringId: string; displayName: string; product: NablaProduct }; avatar: Maybe<{ __typename: "FileUpload"; urlV2: { __typename: "EphemeralUrl"; url: string; expiresAt: ISOString } }> }

const ProviderSummaryFragmentDocument = gql`
fragment ProviderSummary on Doctor {
  uuid
  deactivated
  roles
  subOrganization {
    ...AccountSubOrganizationSummary
  }
}
${AccountSubOrganizationSummaryFragmentDocument}
`
export const ProviderSummaryFragmentProps: Fragment<ProviderSummaryFragment> = {
  document: ProviderSummaryFragmentDocument,
  fragmentName: "ProviderSummary",
  entityName: "Doctor",
  __do_not_use_Data: null
}
export type ProviderSummaryFragment = { __typename: "Doctor"; uuid: UUID; deactivated: boolean; roles: Array<DoctorRole>; subOrganization: AccountSubOrganizationSummaryFragment }

const CopilotApiUserSummaryFragmentDocument = gql`
fragment CopilotApiUserSummary on CopilotApiUser {
  uuid
  subOrganization {
    ...AccountSubOrganizationSummary
  }
}
${AccountSubOrganizationSummaryFragmentDocument}
`
export const CopilotApiUserSummaryFragmentProps: Fragment<CopilotApiUserSummaryFragment> = {
  document: CopilotApiUserSummaryFragmentDocument,
  fragmentName: "CopilotApiUserSummary",
  entityName: "CopilotApiUser",
  __do_not_use_Data: null
}
export type CopilotApiUserSummaryFragment = { __typename: "CopilotApiUser"; uuid: UUID; subOrganization: AccountSubOrganizationSummaryFragment }

const CopilotAssistantUserSummaryFragmentDocument = gql`
fragment CopilotAssistantUserSummary on CopilotAssistantUser {
  uuid
  subOrganization {
    ...AccountSubOrganizationSummary
  }
}
${AccountSubOrganizationSummaryFragmentDocument}
`
export const CopilotAssistantUserSummaryFragmentProps: Fragment<CopilotAssistantUserSummaryFragment> = {
  document: CopilotAssistantUserSummaryFragmentDocument,
  fragmentName: "CopilotAssistantUserSummary",
  entityName: "CopilotAssistantUser",
  __do_not_use_Data: null
}
export type CopilotAssistantUserSummaryFragment = { __typename: "CopilotAssistantUser"; uuid: UUID; subOrganization: AccountSubOrganizationSummaryFragment }

const IdentityFragmentDocument = gql`
fragment Identity on Identity {
  uuid
  acceptableLoginMethods
  ... on ProviderIdentity {
    provider {
      ...ProviderSummary
    }
  }
  ... on CopilotApiUserIdentity {
    user {
      ...CopilotApiUserSummary
    }
  }
  ... on CopilotAssistantUserIdentity {
    user {
      ...CopilotAssistantUserSummary
    }
  }
}
${ProviderSummaryFragmentDocument}
${CopilotApiUserSummaryFragmentDocument}
${CopilotAssistantUserSummaryFragmentDocument}
`
export const IdentityFragmentProps: Fragment<IdentityFragment> = {
  document: IdentityFragmentDocument,
  fragmentName: "Identity",
  entityName: "Identity",
  __do_not_use_Data: null
}
export type IdentityFragment = { uuid: UUID; acceptableLoginMethods: Array<LoginMethod> }&({ __typename: "ProviderIdentity"; provider: ProviderSummaryFragment } | { __typename: "CopilotApiUserIdentity"; user: CopilotApiUserSummaryFragment } | { __typename: "CopilotAssistantUserIdentity"; user: CopilotAssistantUserSummaryFragment } | { __typename: "FutureValue" })

const IdentitiesFragmentDocument = gql`
fragment Identities on Account {
  uuid
  identities {
    ...Identity
  }
}
${IdentityFragmentDocument}
`
export const IdentitiesFragmentProps: Fragment<IdentitiesFragment> = {
  document: IdentitiesFragmentDocument,
  fragmentName: "Identities",
  entityName: "Account",
  __do_not_use_Data: null
}
export type IdentitiesFragment = { __typename: "Account"; uuid: UUID; identities: Array<IdentityFragment> }

export type AccountIdentitiesData = IdentitiesFragment&{
  email: Maybe<string>;
  unverifiedEmail: string;
  locale: SupportedLocale;
  timezone: TimeZone;
  hasPassword: boolean;
  mfaState: ({ __typename: "SetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean } | { __typename: "SmsMfaMethod"; isSetup: boolean; phone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "NotSetupMfaState"; supportedMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean; tentativeQrCode: string } | { __typename: "SmsMfaMethod"; isSetup: boolean; tentativePhone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" })
}
export type AccountIdentitiesVariables = { [key: string]: never }
export const AccountIdentities: Query<AccountIdentitiesData, AccountIdentitiesVariables, false, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
query AccountIdentities {
  me {
    ...Identities
    email
    unverifiedEmail
    locale
    timezone
    hasPassword
    mfaState {
      ... on SetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
          }
          ... on SmsMfaMethod {
            isSetup
            phone
            mfaBySmsAntiAbuseToken
          }
        }
      }
      ... on NotSetupMfaState {
        supportedMethods {
          ... on TotpMfaMethod {
            isSetup
            tentativeQrCode
          }
          ... on SmsMfaMethod {
            isSetup
            tentativePhone
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
  }
}
${IdentitiesFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetFreshMfaBySmsAntiAbuseTokenData = { __typename: "Account"; mfaState: ({ __typename: "NotSetupMfaState"; supportedMethods: Array<({ __typename: "SmsMfaMethod"; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } | { __typename: "FutureValue" }) }
export type GetFreshMfaBySmsAntiAbuseTokenVariables = { [key: string]: never }
export const GetFreshMfaBySmsAntiAbuseToken: Query<GetFreshMfaBySmsAntiAbuseTokenData, GetFreshMfaBySmsAntiAbuseTokenVariables, false, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
query GetFreshMfaBySmsAntiAbuseToken {
  me {
    mfaState {
      ... on NotSetupMfaState {
        supportedMethods {
          ... on SmsMfaMethod {
            mfaBySmsAntiAbuseToken
          }
        }
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type ChooseAccountCredentialsAuthenticatedData = { __typename: "ChooseAccountCredentialsOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type ChooseAccountCredentialsAuthenticatedVariables = { newPassword: string; verifyMfaCode?: Maybe<string> }
export const ChooseAccountCredentialsAuthenticated: Mutation<ChooseAccountCredentialsAuthenticatedData, ChooseAccountCredentialsAuthenticatedVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation ChooseAccountCredentialsAuthenticated($newPassword: String!, $verifyMfaCode: String) {
  chooseAccountCredentials(
    mfa: {verify: {code: $verifyMfaCode}}
    newPassword: $newPassword
  ) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "chooseAccountCredentials",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SetupMfaMethodData = { __typename: "SetupMfaMethodOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type SetupMfaMethodVariables = { mfaCode?: Maybe<string>; newMethod: SetupMfaInput }
export const SetupMfaMethod: Mutation<SetupMfaMethodData, SetupMfaMethodVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation SetupMfaMethod($mfaCode: String, $newMethod: SetupMfaInput!) {
  setupMfaMethod(mfaCode: $mfaCode, newMethod: $newMethod) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "setupMfaMethod",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateCopilotApiOrganizationData = { __typename: "CreateCopilotApiOrganizationOutput"; organization: { __typename: "Organization"; uuid: UUID }; initialIdentity: { __typename: "CopilotApiUserIdentity"; uuid: UUID; acceptableLoginMethods: Array<LoginMethod>; user: CopilotApiUserSummaryFragment } }
export type CreateCopilotApiOrganizationVariables = { organizationInput: CopilotApiOrganizationInput; initialIdentityInput: InitialCopilotApiUserIdentityInput }
export const CreateCopilotApiOrganization: Mutation<CreateCopilotApiOrganizationData, CreateCopilotApiOrganizationVariables, "ACCOUNT"> = {
  schemaType: "ACCOUNT",
  document: gql`
mutation CreateCopilotApiOrganization($organizationInput: CopilotApiOrganizationInput!, $initialIdentityInput: InitialCopilotApiUserIdentityInput!) {
  createCopilotApiOrganization(
    organization: $organizationInput
    initialIdentity: $initialIdentityInput
  ) {
    organization {
      uuid
    }
    initialIdentity {
      uuid
      acceptableLoginMethods
      user {
        ...CopilotApiUserSummary
      }
    }
  }
}
${CopilotApiUserSummaryFragmentDocument}
`,
  endpointName: "createCopilotApiOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}